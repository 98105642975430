import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import logo from '../images/logo_ssafe.svg'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import ssafe_protocol from '../downloads/SSAFE.pdf'
import ssafe_PIS from '../downloads/SSAFETY1_PIS.pdf'

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
<GatsbySeo title='PanSurg SSAFE'
      description='Staff and SAfety eFfects of Epidemics'/>
  <Hero title="PanSurg SSAFE"
        subtitle="Staff and SAfety eFfects of Epidemics"
        fluid={data.hero.childImageSharp.fluid}
        buttonTxt = "Participate"
        buttonLink= "https://docs.google.com/forms/d/e/1FAIpQLSfMb1f7M9ZG_MMbCFo-nII-pRTXRJfFKroaEa4hz0lX7iBjrQ/viewform?fbzx=6071366802869538435"
        breadcrumbs
        secondcrumb = 'Research'
        secondlink = '/research'
        activecrumb = 'SSAFE'
  />

  <section className="section is-small" style={{marginTop:'2rem'}}>
    <div className="container">
      <div className="is-thin">

        <div className="has-text-centered">
          <img src={logo} width="400px" alt="SSAFE Logo" style={{marginBottom:'2rem'}}/>
        </div>

        <div className="notification box has-text-centered">
          <p className="subtitle has-text-darkBlue has-text-weight-bold">Data from SSAFE is now available to view online!</p>
          <Link className="button is-red is-rounded" to="/ssafe_data">View SSAFE data</Link>
        </div>

        <div className="content">

          <p>The COVID-19 pandemic will be a testing and troubling
          time for healthcare workers in the United Kingdom. The wellbeing of NHS
          staff has quite rightly been highlighted in the national media. The PanSurg 'Staff and
          SAfety eFfects of Epidemic' (SSAFE) study will investigate the impact
          of the COVID-19 pandemic on the wellbeing of healthcare professionals.</p>
          <p>PanSurg SSAFE aims to provide direct benefit to frontline healthcare staff in realtime by:</p>
            <ul>
              <li>Investigating staff safety, psychological wellbeing and burnout.</li>
              <li>Providing open access data to allow identification workforce issues.</li>
              <li>Deriving insights that assist workforce planning during and after the pandemic.</li>
              <li>Identifying areas where healthcare workers may benefit from workplace wellbeing interventions.</li>
            </ul>
       
          <div className="buttons" style={{justifyContent:'space-around'}}>
            <a className="button is-red is-rounded"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfMb1f7M9ZG_MMbCFo-nII-pRTXRJfFKroaEa4hz0lX7iBjrQ/viewform?fbzx=6071366802869538435">Participate</a>
            <a className="button is-darkBlue is-rounded"
            href="https://drive.google.com/open?id=1yiZdt98kH2ghgoFm5KxjwQMeejqILu4G">Participant information sheet</a>
            <a className="button is-darkBlue is-rounded"
            href="https://drive.google.com/open?id=1Odpo1iASbNKS8od4NrmEkh6q2QjpFHfX">Study protocol</a>
          </div>
          <h1 className="title has-text-darkBlue is-spaced">
        SSAFEFTY1
      </h1>          <p>As part of the 'Staff and Safety eFfects of Epidemic on Foundation Trainees in Year 1' 
            (SSAFEFTY1) study we are collecting data on the impact of COVID-19 on the safety attitudes and 
            wellbeing of recent medical graduates deployed to interim Foundation Year 1 (FY1) posts. 
            Please answer based on your experiences since deployment.</p>
            <div className="buttons" style={{justifyContent:'space-around'}}>
            <a className="button is-red is-rounded"
            href="https://docs.google.com/forms/d/e/1FAIpQLScFxA5ulpoQbPX9LBooidt9R_Fk7_LDvOJ4MAkiPvvQyjLXPA/viewform">Participate</a>
            <a className="button is-darkBlue is-rounded"
            href={ssafe_PIS}>Participant information sheet</a>
            <a className="button is-darkBlue is-rounded"
            href={ssafe_protocol}>Study protocol</a>
          </div>
          </div>
        </div>
      </div>
    </section>

  <section className="section is-small">
    <div className="container">
      <div className="content is-size-7 is-thin">

          <p><b>Data collection is completely anonymous; please answer the questions as truthfully as possible.</b> </p>
          <p>We are committed to ensuring that your privacy is protected and we strictly adhere to the provisions of all
          relevant Data Protection Legislation, including GDPR, ensuring all personal data is handled in line
          with the principles outlined in the regulation. Please see our <Link to="/privacy-policy">privacy policy</Link> for more information.</p>
          <p><b>By participating in the study, you acknowledge and agree to the following statements:</b></p>
          <ul>
            <li>I confirm that I have read and understood the participant information sheet dated 27/03/20 version 2.0 for the above study and have been given contact details of a study investigator should I want the opportunity to ask further questions. </li>
            <li>I understand that my participation is voluntary, and I am free to withdraw at any time, without giving any reason and without my legal rights being affected.</li>
            <li>I consent for information collected to be used to support other research in the future, including those outside of the EEA.      </li>
            <li>I consent to take part in the above study.</li>
          </ul>
          <p>Under the Imperial College Research Ethics Committee process, the study has been reviewed by the Joint Research Compliance Office and Head of Division/Department (or Principal), where no significant ethical issues have been identified in the protocol or ethics application, can be approved without requiring it to go to full committee.</p>
          <p><b>ICREC reference: 20IC5890</b></p>
          <br />
      <p>Please contact <a href="mailto:max.denning@nhs.net">max.denning@nhs.net</a> for further information</p>

        </div>
      </div>
    </section>


  </>
  )
}
